import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/QCommerce/icon-adv-1.png";
import iconAdv2 from "../images/QCommerce/icon-adv-2.png";
import iconAdv3 from "../images/QCommerce/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const QCommercePage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: (
      <>
        Q-commerce Delivery
        <br className="d-md-none" /> Management & Optimization
      </>
    ),
    subtitle: `Orders.co integration and Master Menu Management allows employees to fulfill orders and
            update item availability in one device. Completely eliminating all time wasted jumping between
            multiple tablets.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="menu management"
        // src="../images/QCommerce/menu-management-2.png"
        alt="Q-commerce"
        src="../images/QCommerce/Q-commerce.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Item Updates",
      description: `Eliminate orders for out-of-stock items with one-click item publishing updates
                across all platforms.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Unified Order List",
      description: `Free up precious counter space and increase employee productivity with
                a unified order list. All your ordering platforms in one place.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Detailed Reporting",
      description: `Automatically generate nightly sales reports and create custom filters
                to quickly review store and item performance.`,
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      testimonialsState.stirred_coffee_house,
      testimonialsState.dors_craft_beer_and_kitchen,
      testimonialsState.c_viche,
      // testimonialsState.jonahs_kabob,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Tablet Frustration",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Expedite order fulfillment and eliminate the need for staff to learn
            multiple interfaces with Orders.co Integration.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering platform"
        src="../images/QCommerce/menu-management.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-big",
    title: "Updating Inventory Across Multiple Menus - Once With Menu Sync",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Updating menus across multiple tablets is confusing and time consuming,
            eliminating the hassle with one-and-done updates from Orders.co’s Menu Sync.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering webiste onboarding"
        src="../images/QCommerce/Ordering website.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "Orders Directly Injected Into POS",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Remove the need for any other devices entirely with direct PoS order injection.
            Receiver orders and track sales directly in your existing PoS software.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="Master Menu Management"
        src="../images/QCommerce/Master-Menu-Management.png"
      />
    ),
  };

  const questions = [
    {
      question: `How does your software work with our existing POS?`,
      answer: `Orders.co works with a wide range of POS companies to directly inject orders
                from your various platforms directly into your POS System. Online orders are aggregated
                into our system and then pushed to your in-store device.`,
    },
    {
      question: `Do you offer a reporting feature?`,
      answer: `Orders.co offers a simple, yet effective, reporting suite that allows users to review
                a variety of reports on a range of metrix to optimize online operations. Administrators
                can also save custom filters to view specific platforms, item performance, or times
                with ease.`,
    },
    {
      question: `Do you provide the tablets to our stores?`,
      answer: `Orders.co provides our unique all-in-one device to every restaurant partner free of charge.
                An integrated tablet allows interaction with our full management system and an integrated
                printer makes order processing a breeze.`,
    },
    {
      question: `How will Menu Sync help our day-to-day?`,
      answer: `Orders.co’s Menu Sync allows in-store staff to update item availability with just one click
                for all connected platforms. Saving time and reducing costly errors.`,
    },
    {
      question: `How does Orders.co benefit our employees?`,
      answer: `Orders.co provides an abundance of daily operation benefits to employees from in-store to
                the administrative level. Admins can review reports from individual stores or a collective,
                making tough decisions near trivial. Ground level employees can save precious time fulfilling
                online orders by only needing to use one device. Lowering wait times for in-store customers
                and freeing up valuable counter space`,
    },
  ];

  return (
    <>
      <Seo
        title={"Q-commerce"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-80px",
            paddingTop: "40px",
          }}
        >
          <Features title="Top Benefits" items={features}></Features>
        </div>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps}></Testimonials>
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm} />
          <News items={data.allWpPost} />
          <Faq items={questions} />
          <div ref={formRef}>
            <ContactForm
              title={
                <>
                  Manage And
                  <br /> Optimize Your
                  <br /> Q-commerce
                </>
              }
            ></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "q-commerce" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "q-commerce"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default QCommercePage;
